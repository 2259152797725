import { Button, Col, Row, Space, Typography, Grid } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import TransactionTable from "./TransactionTable";
import { Coinbase_Auth_Token } from "../../const/const";
import CryptoAccountLoginStatus from "../CryptoAccountLoginStatus";
import { useAppBreakpoint } from '../../hooks/breakpoints';
import { useAuth } from "../../contexts/auth-context";

import './styles/TransactionList.css';



// GK 2023-10-22 - Creates TransactionList (we put this on main page)
const TransactionList = () => {
  const storage = localStorage;
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [inProgress, setInProgress] = useState(true);
  const cryptoAccount = useSelector(state => state.cryptoAccount.account);
  const isMediumScreen = useAppBreakpoint('sm');
  const auth = useAuth();
  const loginEmail = auth.currentUser?.email; //storage.getItem('email');
  console.log('EMAIL ==>', loginEmail);
  //TODO: Move this to the redux state
  // GK 2023-10-22 - The below function grabs transactions from our database,
  const getTransactions = async () => {
    try {
      const response = await axios.post('/api/transactions', {
        loginEmail,
        accountId: null, //result[0]?.id,
        accessToken: storage.getItem(Coinbase_Auth_Token),
      });
  
      console.log('Mongo Transactions', response.data);
      setTransactions(response.data);
      setInProgress(false);

    } catch (error) {
      console.log('ALL Transactions Error ->', error);
      setInProgress(false);
    }
  }

  // Monitor pending transactions and update table when there is a new update
  // This is like a "ChronJob"
  const handleInterval = () => {
    const interval = setInterval(() => {
      const pendingTransactions = transactions
      .filter(
          transaction => transaction.txn_status.toLowerCase() === 'pending');
      if(pendingTransactions.length > 0) {
        getTransactions();
      }

    }, 3*60*1000); // Delay is 3 mins

    return interval;
  }

  useEffect(() => {
    console.log('Email Changed');
    if(loginEmail) {
      console.log('Email has value ****');
      getTransactions();
    }
  }, [loginEmail])

  useEffect(() => {
    // this runs the chronjob
    const interval = handleInterval();

    // when you navigate away from the page, remove the chronjob
    return () => clearInterval(interval);
  }, []);

  // GK 2023-10-22 - creates the transaction list page
  // note: the actual transaction list comes from the TransactionTable below
  return (
    <div style={{ marginTop: "10px" }}>
      <div className="text-center" style={{marginBottom: '10px'}}>
        <CryptoAccountLoginStatus/>
      </div>
      <Row gutter={[16, 32]}>
        <Col md={{span: 22, offset: 1}} xs={{span: 24}} className="transaction-actions-section">
          <Button
            block={!isMediumScreen}
            type="primary"
            className="btn-transaction"
            onClick={() => {
              navigate("/transaction");
            }}
          >
            Make Transaction
          </Button>
          <Button
            block={!isMediumScreen}
            type="primary"
            className="btn-transaction"
            onClick={() => {
              navigate("/crypto-login");
            }}
          >
            Sign in to Crypto Exchange
          </Button>
        </Col>
        <Col md={{span: 22, offset: 1}} xs={{span: 24}}  >
          <TransactionTable transactions={transactions} inProgress={inProgress} />
        </Col>
      </Row>
      
      <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
          <p class="auth-title-text" justify="center"> Video to <a class="light-blue" href="https://youtu.be/WaPv0scN3-c" target="_blank"> get started </a>with Punkypay</p>
        </Col>
      </Row>
    </div>
  );
};


export default TransactionList;
