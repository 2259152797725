// GK 2023-10-21 - Auth Component deals with user authentication, of course

import { useState, useEffect } from "react";
import { Button, Form, Input, Checkbox, message, Alert, Col, Row } from "antd";
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/auth-context';
import './styles/AuthComponent.css';

// GK 2023-10-21 - the "title" below is a variable you use when calling the AuthComponent 
// so you get the proper part of the auth component, eg. <AuthComponent title="Login"/>
// and <AuthComponent title="Register"/>

const AuthComponent = ({ title }) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(true);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [authError, setAuthError] = useState('');

  useEffect(() => {
    if(auth.loginDetails) {
      navigate('/transactions')
    }
  }, []);

  // GK 2023-10-21 - defines the register handler for this component 
  const handleRegister = async () => {
    // GK 2023-10-21 - tries to register user in firebase and set the email sign-up status to true
    try {
      setAuthError('');
      await auth.signUp(email.toLowerCase().trim(), password); // firebase signup
      setSignupSuccess(true);// set signup status to true

    // GK 2023-10-21 - if user already exists, show error message
    } catch (error) {
      console.log('Firebase Error =>', error);
      if (error.code === "auth/email-already-in-use") {
        messageApi.open({
          type: "error",
          content: "Email already in use",
        });
        setAuthError('Email already in use');
        return;
      }
      // GK 2023-10-21 - if the "if" statement in the catch block fails, then show the below error message
      setAuthError('Something went wrong. Try again!');
    }
  }

  // GK 2023-10-21 - defines the "isRegister" function, basically used to see if we are on the regiter screen
  const isRegister = () => title.toLowerCase() === 'register';

  // GK 2023-10-21 - Handles logins and errors to be shown to the user
  const handleLogin = async () => {
    try {
      setAuthError('');
      await auth.signIn(email.toLowerCase().trim(), password);
    } catch (error) {
      console.log('Login error', error);
      if (error.code === "auth/wrong-password") {
        messageApi.open({
          type: "error",
          content: "Password is invalid. Please try again!",
        });
        setAuthError('Password is invalid. Please try again!');
      }
      if (error.code === "auth/user-not-found") {
        messageApi.open({
          type: "error",
          content: "Email is invalid.",
        });
        setAuthError('Email is invalid. Please try again!');
      }
    }
  }

  //GK 2023-10-21 - when submitting the register form, it runs this function. 
  // The function basically reads, 
  // "If the form has the Register title, then register an account. Otherwise, log the user in"
  const onFinish = () => {
    if(isRegister()) {
      if(!termsAndConditions) {
        setAuthError('You can only register if you accept our Terms of Use.');
        return;
      }
      
      handleRegister();
      return;
    }

    handleLogin();
  }

  // GK 2023-10-21 - runs if there is a failure after form submit
  // AntDesign supplies the errorInfo automatically
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  
  // GK 2023-10-21 - below defines how the form actually looks/behaves
  return (
    <div style={{paddingTop: 20}}>
      <Row>
        <Col xs={{span: 24}} md={{span: 16, offset: 4}}>
            {
              isRegister() && signupSuccess && 
              (<Alert 
                message={`Verification email sent. Don't forget to check your Spam!`} 
                type="success"
                className="text-center" 
              />)
            }
            {
              authError && 
              (<Alert 
                message={authError} 
                type="error"
                className="text-center" 
              />)
            }
            {
              !isRegister() && auth.userIsVerified === false && 
              (<Alert 
                message={'This account needs email verification. Please check your email!'} 
                type="warning"
                className="text-center" 
              />)
            }
        </Col>
      </Row>
      <h2> {title} Form</h2>
      <Form
        name="basic"
        //layout="vertical"
        labelCol={{
          xs: {span: 24},
          md: {span: 8}
        }}
        wrapperCol={{
          xs: {span: 24},
          md: {span: 8},
        }}
        initialValues={{
          termsAndConditions: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter your email!",
            },
          ]}
          className="form-item"
        >
          <Input onChange={(e) => setEmail(e.target.value)} />
        </Form.Item>

        <Form.Item
          className="form-item"
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        {
          title === 'Register' && (
            <Form.Item
              // className="form-item"
              name="termsAndConditions"
              valuePropName="checked"
              wrapperCol={{
                md: {
                  offset: 8,
                  span: 16,
                },
                xs: {
                  span: 24,
                }
              }}
            >
              <Checkbox  
                checked={termsAndConditions} 
                onChange={(e) => setTermsAndConditions(e.target.checked)}
              >
                By signing up, you agree to our <a class="light-blue" href="terms-of-Use">Terms of Use</a>
              </Checkbox>
            </Form.Item>
          )
        }
        <Form.Item
          className="form-item form-item__submit"
          wrapperCol={{
            md: {
              offset: 8,
              span: 16,
            },
            xs: {
              span: 24,
            },
          }}
        >
          <Button type="primary" htmlType="submit">
            {title}
          </Button>
        </Form.Item>
      </Form>
      <Row className="auth-links-section">
        <Col 
          xs={{span: 24}}
          md={{span: 16, offset: 8}}
        >
          {title === "Login" && (
              <div>
                <Link to="/password-reset" className="text-link">Forget your password? Click here</Link>
              </div>
          )}
          <div>
            {title === "Register" && (
              <Link to="/login" className="text-link">Already have account? Go to Login</Link>
            )}
            {title === "Login" && (
              <Link to="/register" className="text-link">Don't have account? Go to Register</Link>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AuthComponent;
