import React, { useEffect, useState } from "react";
import { Outlet, useLocation, Link, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Button, message, Layout, Menu, ConfigProvider, theme, Space } from "antd";
import { 
  UserOutlined, 
  HomeOutlined, 
  LogoutOutlined,
  LoginOutlined, 
  TransactionOutlined, 
  BookOutlined 
} from '@ant-design/icons';

import Sidebar from './Components/Sidebar/Sidebar';
import { useAuth } from './contexts/auth-context';
import { useAppBreakpoint } from './hooks/breakpoints';
import { LAST_VISITED_LOCATION } from "./const/const";
import { registerAuthorizationHeader } from "./environment";
import appLogo from './app-logo.svg';
import { PUBLIC_URLS } from "./routes/router";
import "./App.css";

const { Header, Content, Footer } = Layout;
const { defaultAlgorithm, darkAlgorithm } = theme;
const PUBLIC_SIDEBAR_ITEMS = [
  {
    key: '1',
    icon: React.createElement(HomeOutlined),
    label: 'Home',
    link: '/'
  },
  {
    key: '2',
    icon: React.createElement(LoginOutlined),
    label: 'Login',
    link: '/login'
  },
  {
    key: '3',
    icon: React.createElement(UserOutlined),
    label: 'Register',
    link: '/register'
  }
]

const PROTECTED_SIDEBAR_ITEMS = [
  {
    key: '1',
    icon: React.createElement(TransactionOutlined),
    label: 'Transaction List', //Transactions
    link: '/transactions'
  },
  // {
  //   key: '2',
  //   icon: React.createElement(BookOutlined),
  //   label: 'Get Started',
  //   link: '/get-started'
  // },
];

const LOGOUT_ITEM = {
  key: String(PROTECTED_SIDEBAR_ITEMS.length + 1),
  icon: React.createElement(LogoutOutlined),
  label: 'Logout',
  link: '/'
}

// GK 2023-11-02 - the wrapper to all of the components
function App() {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const auth = useAuth();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { token: {colorBgContainer} } = theme.useToken();
  const sideBarItems = (auth.loginDetails ? PROTECTED_SIDEBAR_ITEMS : PUBLIC_SIDEBAR_ITEMS);
  const isLargeScreen = useAppBreakpoint('lg');
  const storage = localStorage;

  useEffect(() => {// if user navigates by www subdomain, redirect to app main domain without www.
    const href = window.location.href;
    const subdomain = window.location.hostname.split('.')[0];
    if(subdomain.toLowerCase() === 'www') {
      const searchText = 'www.';
      const searchRegEx = new RegExp(searchText, 'i');
      const newHref = href.replace(searchRegEx, ''); // strip off "www." from the domain url

      window.location.href = newHref;
    }
  }, []);

  useEffect(() => {
    //if the url is public or not logged in, don't monitor location. Just return the function
    if(PUBLIC_URLS.includes(location.pathname) || !auth.loginDetails) { 
      return;
    }
    // otherwise, set the "location" to where the user was last
    storage.setItem(LAST_VISITED_LOCATION, location.pathname);

  }, [location])

  // 2023-10-24 GK - Authorization Header for making calls to the API
  useEffect(() => {
    registerAuthorizationHeader(auth.loginDetails?.accessToken);
  }, []);

  const handleLogout = () => {
    auth.signOut();
    if(!isLargeScreen) {
      setIsSidebarOpen(false);
    }
  };

  const handleSideBarNav = (param) => {
    if(auth.loginDetails && param.key === LOGOUT_ITEM.key) {
      handleLogout();
      return;
    }
    
    const foundItem = sideBarItems.find(item => item.key === param.key);
    if(!foundItem || !foundItem.link) {
      return;
    }

    navigate(foundItem.link);
    setIsSidebarOpen(false);
  }

  // Get the side bar key for the current location and use it to highlight the hamburger menu
  const getDefaultKey = () => {
    return sideBarItems.find(item => item.link === location.pathname)?.key;
  }

  if(auth.authenticating) {
    return <div style={{textAlign: 'center', margin: 8}}>Loading...</div>
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: darkAlgorithm,
        // token: {
        //   fontFamily: 'YogaSansProRegular'
        // }
      }}
    >
      <Layout className="app-layout">
          <Sidebar  
            isOpen={isSidebarOpen} 
            onClose={() => setIsSidebarOpen(false)} 
            overlay={true}
          >
            <Menu
              defaultSelectedKeys={[getDefaultKey()]}
              selectedKeys={[getDefaultKey()]}
              theme="dark"
              mode="inline"
              items={ auth.loginDetails ? 
                [...PROTECTED_SIDEBAR_ITEMS, LOGOUT_ITEM] : 
                PUBLIC_SIDEBAR_ITEMS 
              }
              onClick={handleSideBarNav}
            />
          </Sidebar>
        <Layout>

          <Header className="app-layout-header">
            <div className="app-layout-header__left">
              {
                !isLargeScreen && (
                  <Button
                      className="btn-hamburger"
                      type="primary"
                      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                      style={{
                        
                      }}
                    >
                      <div className="hamburger__line"></div>
                      <div className="hamburger__line"></div>
                      <div className="hamburger__line"></div>
                    </Button>
                )
              }
              <div className="app-layout-header__title" >
                <Link className="app-layout-header__title--link" to="/"><img src={appLogo} /></Link>
              </div>
              
            </div>
            {
              isLargeScreen && (
                <div className="app-layout-header__center">
                  {
                    auth.loginDetails && (
                      <div className="nav-items">
                        <Link className="nav-item__link" to="/transactions">Transaction List</Link> 
                        {/* GK July 2024 - Used to read "Transactions" on navigation above */}
                        {/* <Link className="nav-item__link" to="/transactions">Money Management</Link> */}
                        {/* <Link className="nav-item__link" to="/get-started">Get Started</Link> */}
                      </div>
                    )
                  }
                </div>
              )
            }
            {
              isLargeScreen && (
                <div className="app-layout-header__right">
                  {
                    auth.loginDetails && (
                      <Button type="primary" onClick={handleLogout} style={{ margin: 4 }}>
                        Log out
                      </Button>
                    )
                  }
                  {
                    !auth.loginDetails && (
                      <Space>
                        <Button type="primary" onClick={() => navigate('/login')} style={{ margin: 4 }}>
                          Login
                        </Button>
                        <Button type="primary" onClick={() => navigate('/register')} style={{ margin: 4 }}>
                          Register
                        </Button>
                      </Space>
                    )
                  }
                </div>
              )
            }
          </Header>
          <Content className="app-layout-content">
            <Outlet/>
          </Content>
          <Footer style={{ textAlign: 'center' }}>Punkypay Incorporated ©2024. For questions, email <a class="light-blue" href="mailto:support@punkypay.com">support@punkypay.com</a></Footer>
        </Layout>
      </Layout>
      <ToastContainer/>
    </ConfigProvider>
  )
}

export default App;
