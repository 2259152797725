import { useEffect } from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, Space} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';

import './styles/Home.css';

const GetStarted = () => {
    
    return (
        <div>
            <Row justify="center">
                <Col>

                    <h2 className="auth-title-text"> <b> Instructions to Get Started </b> </h2>
                    
                    <h3 className="auth-title-text">
                        {/* The instructions for Punkypay will be updated regularly. Please look at this <a class="light-blue" href="https://docs.google.com/document/d/1-d-72szMeYRttwbosVMXTZlVRVHZe3cUi_NWXc-gI60/edit" target="_blank"> Google Document</a> for detailed instructions.  */}
                    </h3>


                </Col>
            </Row>

            <Row justify="center" gutter={[16, 14]}>
                <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <p class="auth-title-text" justify="center"> Video to <a class="light-blue" href="https://www.youtube.com/watch?v=PQPW_fqtzE0" target="_blank"> get started </a>with Punkypay</p>
                </Col>
            </Row>

          <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/LF_NJgmrPHQ" target="_blank">how to use</a> Coinbase on Punkypay.</p>
            </Col>
          </Row>

        <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://www.youtube.com/watch?v=T2_NY8HbVkY" target="_blank">how to get</a> USDC on Coinbase.</p>
            </Col>
        </Row>

        <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/nwbT19TIZOU" target="_blank">how to use</a> Punkypay Mailroom.</p>
        </Col>
        </Row>
        <Row justify="center" gutter={[16, 14]}>
        <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <p class="auth-title-text" justify="center"> Sheet showing <a class="light-blue" href="https://docs.google.com/spreadsheets/d/1zF7j9L3OAlTa5R78SBgocil3Is2KgLxG-Bm0sa4cWT8/edit#gid=0" target="_blank">exchanges</a> in the Punkypay Mailroom. </p>
        </Col>
        </Row>    

        <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
            <p class="auth-title-text" justify="center"> Video showing <a class="light-blue" href="https://youtu.be/ZymdtQNzXMc" target="_blank">how to make</a> a Transaction.</p>
            </Col>
        </Row>

          <Row justify="center" gutter={[16, 14]}>
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
              <p class="auth-title-text" justify="center"> Document explaining <a class="light-blue" href="https://docs.google.com/document/d/1-d-72szMeYRttwbosVMXTZlVRVHZe3cUi_NWXc-gI60/edit#heading=h.r1kdh7jwjoxk" target="_blank">how Punkypay</a> works. </p>
            </Col>
          </Row>
        </div>
    )
}

export default GetStarted;