import * as React from "react";
import {
  createBrowserRouter,
} from "react-router-dom";
import App from '../App';
import TransactionList from "../Components/Transaction/TransactionList";
import Transaction from "../Components/Transaction/Transaction";
import TransactionDetails from "../Components/Transaction/TransactionDetails";
import AuthComponent from "../Components/Common/AuthComponent";
import CryptoLogin from "../Components/CryptoLogin";
import ManualAddress from "../Components/ManualAddress";
import { AuthProvider } from '../contexts/auth-context';
import RequireAuth from '../Components/Common/RequireAuth';
import AuthActionsLandingPage from "../Components/Common/AuthActionsLandingPage";
import AuthActions from "../Components/Common/AuthActions";
import ConfirmEmail from '../Components/Common/ConfirmEmail';
import PasswordReset from "../Components/Common/PasswordReset";
import ConfirmPasswordReset from "../Components/Common/ConfirmPasswordReset";
import Home from '../Components/Home';
import GetStarted from '../Components/GetStarted';
import UnderstandTXNDetails from '../Components/Transaction/UnderstandTXNDetails';
import HowMuchPunkypaySavesYou from "../Components/HowMuchPunkypaySavesYou";
import TermsOfUse from "../Components/TermsOfUse";
import PrivacyPolicy from "../Components/PrivacyPolicy";

export const PUBLIC_URLS = [
  '/',
  '/login',
   '/register',
   '/confirm-email',
   '/confirm-password-reset',
   '/password-reset',
   '/how-much-punkypay-saves-you',
   '/terms-of-use',
   '/privacy-policy',
]


// GK 2023-10-22 - this is the router for navigating to different pages
const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider><App/></AuthProvider>,
    children: [
      // START: public routes - routes that don't require authentication to access
        {
            index: true,
            element: <Home/>
        },
        {
          path: 'login',
          element: <AuthComponent title="Login" />
        },
        {
            path: 'register',
            element: <AuthComponent title="Register" />
        },
        {
          path: '/confirm-email',
          element: <ConfirmEmail />
        },
        {
          path: '/confirm-password-reset',
          element: <ConfirmPasswordReset/>
        },
        {
          path: '/password-reset',
          element: <PasswordReset/>
        },
        
        { 
          path: '/how-much-punkypay-saves-you',
          element: <HowMuchPunkypaySavesYou/>
        },

        { 
          path: '/terms-of-use',
          element: <TermsOfUse/>
        },
        { 
          path: '/privacy-policy',
          element: <PrivacyPolicy/>
        },
        // END: public routes
        // START: Protected routes - routes that require authentication to access
        {
            path: 'crypto-login',
            element: <RequireAuth><CryptoLogin/></RequireAuth>
        },
        {
            path: 'transactions',
            element:  <RequireAuth><TransactionList /></RequireAuth>
        },
        {
            path: 'transactions/:transactionId',
            element:  <RequireAuth><TransactionDetails/></RequireAuth>
        },
        {
            path: 'transaction',
            element:  <RequireAuth><Transaction/></RequireAuth>
        },
        {
            path: 'manual-address',
            element:  <RequireAuth><ManualAddress/></RequireAuth>
        },
        {
          path: '/get-started',
          element:  <RequireAuth><GetStarted /></RequireAuth>
        },
        { // This isnt working, I am confused
          path: '/understand-txn-details',
          element: <RequireAuth><UnderstandTXNDetails/></RequireAuth>
        }, 
        // END: protected routes
    ],
    
  },
  // Uncomment below if you want to use Online version of firebase
  { // Exclusive firebase route for handling firebase related actions
    path: 'auth/action',
    element: <AuthActions><AuthActionsLandingPage/></AuthActions>
  },
  // START: Route Below is strictly for use by firebase local emulator
  // { // Exclusive local firebase Emulator route for handling firebase related actions
  //   path: '/emulator/action',
  //   element: <AuthActions><AuthActionsLandingPage/></AuthActions>
  // }
  // END: Route Above is strictly for use by firebase local emulator
]);

export default router;
