// This script creates a connection to our punkypay firebase account, using the firebase-config.js
// and then use the connection to create some firebase utility functions

// https://codingpr.com/react-firebase-auth-tutorial/
// https://codingpr.com/react-firebase-registration-tutorial/#create_user_actions_page
// https://codingpr.com/react-firebase-password-reset-feature/
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  connectAuthEmulator,
  applyActionCode,
  sendEmailVerification,
  sendPasswordResetEmail,
  confirmPasswordReset as confirmNewPassworReset
} from 'firebase/auth';
import { getFirebaseConfig } from './firebase-config';

const app = initializeApp(getFirebaseConfig());
const auth = getAuth(app);
// Line below: Connect to local emulator. Comment out if you want to connect to online firebase
//connectAuthEmulator(auth, 'http://127.0.0.1:9099'); // firebase emulators:start

// GK 2023-10-22 - signs into firebase 
export const signInUser = async (email, password) => {
  if (!email && !password) return;

  return await signInWithEmailAndPassword(auth, email, password)
}

// GK 2023-10-22 - signs up with firebase 
export const signUpUser = async (email, password) => {
    if (!email && !password) return;
  
    const response = await createUserWithEmailAndPassword(auth, email, password);
    if(response && auth.currentUser) {
      // GK 2023-10-22 - sends email verification to the user 
      sendEmailVerification(auth.currentUser);
    }

    return response;
  }

// GK 2023-10-22 - this is the subscription event for the userStateListener
export const userStateListener = (callback) => {
  return onAuthStateChanged(auth, callback)
}

// GK 2023-10-22 - signs the user out of Firebase
export const signOutUser = async () => await signOut(auth);

// GK 2023-10-22 - sends the confirmation OTP via email
export const confirmUserEmail = async (oobCode) => {
  if (!oobCode) return;

  return applyActionCode(auth, oobCode);
}

// GK 2023-10-22 - sends the reset password email
export const resetPassword = async (email) => {
  return await sendPasswordResetEmail(auth, email)
}

// GK 2023-10-22 - confirms the password reset with the OTP
export const confirmPasswordReset = async (
  oobCode, newPassword
) => {
  if(!oobCode && !newPassword) return;
  
  return await confirmNewPassworReset(auth, oobCode, newPassword);
}