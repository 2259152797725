import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TransactionTable = (props) => {
  const { transactions, inProgress } = props;
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  // GK 2023-10-22 - creates the actions for when you click on a row in the table
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        console.log('ROW Clicked Event', event, 'Rec', record, 'index', rowIndex);
        navigate(`/transactions/${record.transactionId}`);
      }, // click row
      onDoubleClick: (event) => {}, // double click row
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  }

  // GK 2023-10-22 - These are the columns on the TransactionTabloe
  // GK 2023-10-22 - I need to edit these...
  const columns = [
    {
      title: "Recipient",
      dataIndex: "recipient",
      key: "recipient",
      // fixed: "left",
      width: "150px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      width: "180px",
    },
    {
      title: "Memo",
      dataIndex: "memo",
      key: "memo",
      width: "200px",
    },
    {
      title: "Transaction Time",
      dataIndex: "transactionTime",
      key: "transactionTime",
      width: "200px",
    },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: "100px",
      render: (_, record) => (
        <Button
          type="link"
          style={{ padding: 0 }}
        >
          View
        </Button>
      ),
    },
  ];

  // GK 2023-10-22 - Formats the transactions we pull into the table dataSource format (eg. key, recipient, amount, etc.) 
  useEffect(() => {
    const rows = (transactions || []).map((transaction) => {
      const date = new Date(transaction.created_at);
      const transactionAmount = transaction.txn_amount ? Number(transaction.txn_amount).toFixed(2) : "";
      return {
        key: transaction._id,
        recipient: transaction.recipient_login_email,
        memo: transaction.memo_at_txn,
        transactionTime: `${date}`,
        amount: transactionAmount,
        transactionFee: transaction.blockchain_fees,
        status: transaction.txn_status,
        transactionId: transaction.exchange_txn_id
      }
    });

    setData(rows);
  }, [transactions]);
  

  return (
    <>
      <Table 
        columns={columns}
        dataSource={data}
        scroll={{ x: 1024 }}
        loading={inProgress}
        onRow={onRow}
      />
    </>
  );
};

export default TransactionTable;
